
import { createBrowserRouter } from "react-router-dom";
import DefaultLayout from "./Components/DefaultLayout";
import GuestLayout from "./Components/GuestLayout";
import NotFound from "./NotFound";
import Dashboard from "./Views/Dashboard";
import Login from "./Views/Login";
import Signup from "./Views/Signup";
import User from "./Views/User";
import FreshSaleEntry from './Views/Store/FreshSaleEntry';
import PrintSaleInvoice from "./Views/Store/PrintSaleInvoice";
import Category from "./Views/Products/Category";
import Product from "./Views/Products/Product";
import DailyBrandSale from "./Views/Reports/DailyBrandSale";
import AllSaleInvoices from "./Views/Reports/AllSaleInvoices";
import EditSaleInvoice from "./Views/Store/EditSaleInvoice";
import StockByStore from "./Views/Reports/StockByStore";
import StockStatus from "./Views/Reports/StockStatus";
import Ledger from "./Views/Reports/Ledger";
import DueList from "./Views/Reports/DueList";
import PurchaseEntryInvoice from "./Views/StorePurchase/PurchaseEntryInvoice";
import AllPurchaseInvoices from "./Views/Reports/AllPurchaseInvoices";
import EditPurchaseInvoice from "./Views/StorePurchase/EditPurchaseInvoice";
import OpeningBalance from "./Views/Bank/OpeningBalance";
import RecievePayment from "./Views/Bank/RecievePayment";
import AutoName from "./Views/AutoInvoice/AutoName";
import SettingInvoice from "./Views/AutoInvoice/SettingInvoice";
import Store from "./Views/SystemStore/Store";
import InvoiceSetting from "./Views/SystemStore/InvoiceSetting";
import Sector from "./Views/SectorsManagment/Sector";
import SubSector from "./Views/SectorsManagment/SubSector";
import BatchListInvoices from "./Views/Reports/BatchListInvoices";
import SaleSummeryByBrand from "./Views/Reports/SaleSummeryByBrand";
import SectorSalePbalance from "./Views/Reports/SectorSalePbalance";
import SubSectorSalePbalance from "./Views/Reports/SubsectorSalePbalance";
import SectorDueReport from "./Views/Reports/SectorDueReport";
import ProductsDetail from "./Views/Salesman/ProductsDetail";
import BillDetail from "./Views/Salesman/BillDetail";
import Reports from "./Views/Salesman/Reports";
import MyInvoices from "./Views/Salesman/MyInvoices";
import PointScheme from "./Views/Reports/PointScheme";
import Users from "./Views/Users/Users";
import ProductBarcode from "./Views/Products/ProductBarcode ";
import ExpenseSheet from "./Views/Expense/ExpenseSheet";
import AssignSector from "./Views/SectorsManagment/AssignSector";
import MonthlyExpenseReport from "./Views/Expense/MonthlyExpenseReport";
import BrandPurchaseReport from "./Views/Reports/BrandPurchaseReport";
import ImportProducts from "./Views/Products/ImportProducts";
import GstCustomer from "./Views/Reports/GstCustomer";
import AllDeletedSaleInvoices from "./Views/Reports/AllDeletedSaleInvoices";

const router=createBrowserRouter([
{
path:'/',
element:<DefaultLayout/>,
children: [
    {

        path:'/user',
    element:<User/>
    },
    
  {
        path:'/',
    element:<Dashboard/>
    }

    ,
  {
    path:"/saleentry/:id",
    element:<FreshSaleEntry/>
    },
    {
        path:"/productdetail/:id",
        element:<ProductsDetail/>
        },
        {
            path:"/expensesheet",
            element:<ExpenseSheet/>
            },
            {
                path:"/monthlyexpensereport",
                element:<MonthlyExpenseReport/>
                },
                {
                    path:"/brand/purchase/report",
                    element:<BrandPurchaseReport/>
                    },
    {
        path:"/print/invoice",
        element:<PrintSaleInvoice/>
        },
        {
            path:"/add/category",
            element:<Category/>
            },
          
            {
                path:"/add/product",
                element:<Product/>
                },
                {
                    path:"/gen/barcode",
                    element:<ProductBarcode/>
                    },

                    {
                        path:"/import/products",
                        element:<ImportProducts/>
                        },
                {
                    path:"/dailybrandsalereport",
                    element:<DailyBrandSale/>
                    },
                    {
                        path:"/allsaleinvoices",
                        element:<AllSaleInvoices/>
                        },
                        {
                            path:"/alldeletedsaleinvoices",
                            element:<AllDeletedSaleInvoices/>
                            },
                        {
                            path:"/editsaleinvoice/:id",
                            element:<EditSaleInvoice/>
                            },

                            {
                                path:"/stockbystore",
                                element:<StockByStore/>
                                },
                                {
                                    path:"/stockstatus",
                                    element:<StockStatus/>
                                    },
                                    {
                                        path:"/gstcustomerreport",
                                        element:<GstCustomer/>
                                        },
                                    {
                                        path:"/ledger",
                                        element:<Ledger/>
                                        },
                                        {
                                            path:"/duelist",
                                            element:<DueList/>
                                            },
                                            {
                                                path:"/purchase/entry/invoice",
                                                element:<PurchaseEntryInvoice/>
                                                },
                                                {
                                                    path:"/all/purchase/invoices",
                                                    element:<AllPurchaseInvoices/>
                                                    },

                                                    {
                                                        path:"/editpurchaseinvoice/:id",
                                                        element:<EditPurchaseInvoice/>
                                                        },
                          
                                                        {
                                                            path:"/openingbalance",
                                                            element:<OpeningBalance/>
                                                            },
                              
                                                            {
                                                                path:"/recievepayment",
                                                                element:<RecievePayment/>
                                                                },
                                                                {
                                                                    path:"/autoname",
                                                                    element:<AutoName/>
                                                                    },
                                                                    {
                                                                        path:"/settingautoname",
                                                                        element:<SettingInvoice/>
                                                                        },

                                                                        {
                                                                            path:"/systemstore",
                                                                            element:<Store/>
                                                                            },
                                                                            {
                                                                                path:"/systeminvoice",
                                                                                element:<InvoiceSetting/>
                                                                                },
                                                                                {
                                                                                    path:"/addsector",
                                                                                    element:<Sector/>
                                                                                    },
                                                                                    {
                                                                                        path:"/addsubsector",
                                                                                        element:<SubSector/>
                                                                                        },
                                                                                        {
                                                                                            path:"/assignsector",
                                                                                            element:<AssignSector/>
                                                                                            },
                                                                                        {
                                                                                            path:"/batchlistinvoices",
                                                                                            element:<BatchListInvoices/>
                                                                                            },
                                                                                            {
                                                                                                path:"/salesummerybybrand",
                                                                                                element:<SaleSummeryByBrand/>
                                                                                                },
                                                                                                {
                                                                                                    path:"/sectorsalepbalance",
                                                                                                    element:<SectorSalePbalance/>
                                                                                                    },
                                                                                                    {
                                                                                                        path:"/subsectorsalepbalance",
                                                                                                        element:<SubSectorSalePbalance/>
                                                                                                        },
                                                                                                        {
                                                                                                            path:"/due/sector/report",
                                                                                                            element:<SectorDueReport/>
                                                                                                            },
                                                                                                            {
                                                                                                                path:"billdetail",
                                                                                                                element:<BillDetail/>
                                                                                                                },
                                                                                                                {
                                                                                                                    path:"reports",
                                                                                                                    element:<Reports/>
                                                                                                                    },
                                                                                                                    {
                                                                                                                        path:"myinvoices",
                                                                                                                        element:<MyInvoices/>
                                                                                                                        },
                                                                                                                        {
                                                                                                                            path:"pointscheme",
                                                                                                                            element:<PointScheme/>
                                                                                                                            },
                                                                                                                            {
                                                                                                                                path:"userslist",
                                                                                                                                element:<Users/>
                                                                                                                                },
    



 

]
},

{
    path:'/',
    element:<GuestLayout/>,
    children:[
        {

            path:'/login',
        element:<Login/>
        },
        {
            path:'/signup',
        element:<Signup/>
        }
        


    ]
    
    },


    
   

{
    path:'*',
element:<NotFound/>
}


]);
export default router