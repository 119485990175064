import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

import { FaCartArrowDown, FaMinusCircle, FaPlusCircle } from 'react-icons/fa';
import { useStateContext } from '../Context/ContextProvider';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { TextField } from '@mui/material';
function ProductsDetail() {
    const{items,setItems}=useStateContext();
    const  {id}  = useParams();
    const stringitems = JSON.parse(items);
    
    const [products,setProducts]=useState();
    const [norecord,setNoRecord]=useState(false);
    const [isClick,setIsClick]=useState(true);
    const [totalPages, setTotalPages] = useState(1);

    const [searchQuery,setSearchQuery]=useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [modalShow, setModalShow] = useState(false);
    
const [completeitemprop,setCompleteItemProp]=useState([]);

    function MyVerticallyCenteredModal(props) {
      const [saleprice, setSalePrice] = useState(props.completeitem.unitsaleprice);
      const [saleqty, setSaleQty] = useState(1);
      const {items}=useStateContext();
      const stringitems = JSON.parse(items);
      const [discountpercent, setDiscountPercent] = useState(props.completeitem.extra_discount);
      const [subtotaldiscount, setSubTotalDiscount] = useState(props.completeitem.extra_discount);
      
      const [subtotal, setSubtotal] = useState(saleqty*props.completeitem.unitsaleprice);
    //const [completeitem, setCompleteItem] = useState(props.completeitem);
    
  
    const handleInc=()=>{
      const updateqty=parseFloat(saleqty)+1;
    
      if(updateqty<=props.completeitem.qty){
setSaleQty(updateqty);
setSubtotal(parseFloat(updateqty*saleprice).toFixed(2));
setSubTotalDiscount(updateqty*props.completeitem.extra_discount);
      }
else{
      
   Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Store Does Not Have Enough Items',
                showConfirmButton: false,
                timer: 1500
              })
 
      }

    }
    const handleDec=()=>{
      const updateqty=parseFloat(saleqty)-1;
      if(updateqty>=1){
setSaleQty(updateqty);
setSubtotal(parseFloat(updateqty*saleprice).toFixed(2));
setSubTotalDiscount(updateqty*props.completeitem.extra_discount);

      }else{
      
   Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Cannot Go Below 1',
                showConfirmButton: false,
                timer: 1500
              })
 
      }


    }
    
    const handleSubTotalDiscount=(event)=>{
      
 
    const discountvalue=event.target.value;
    
    //active it for discount percentage 
    //const total=parseFloat(saleqty*saleprice).toFixed(2);
    //const disval=total-discountvalue;
    //const disper=(((total-disval)/total)*100).toFixed(2);
    //const disper=(discountvalue).toFixed(2);
  
 setDiscountPercent(discountvalue);
 setSubTotalDiscount(discountvalue);   
 
    
      
    }

    const handleSaveModel=(e)=>{
      
      e.preventDefault();

if(stringitems===null){
  const stringitems=[];
  const remqty={
    'remaining':props.completeitem.qty,
    'discountpercent':discountpercent,
    'subtotaldiscount':subtotaldiscount,
    
     }
      setItems([
        ...stringitems,
        { ...props.completeitem, qty: saleqty,unitsaleprice:saleprice,...remqty }
      ]);
    
      setModalShow(false);
}
else{
  const remqty={
    'remaining':props.completeitem.qty,
    'discountpercent':discountpercent,
    'subtotaldiscount':subtotaldiscount,
    

     }
      setItems([
        ...stringitems,
        { ...props.completeitem, qty: saleqty,unitsaleprice:saleprice,...remqty }
      ]);
    
      setModalShow(false);
}

       
        
      }
    const handleChangeSalePrice=(e)=>{
    
      setSalePrice(e.target.value);
      setSubtotal(parseFloat(saleqty*e.target.value).toFixed(2));
      
          }
          const handleChangeSaleQty=(e)=>{
            const saleqty=e.target.value===''?0:e.target.value;
 if(parseFloat(saleqty)<=props.completeitem.qty){
            setSaleQty(e.target.value);
setSubTotalDiscount(e.target.value*props.completeitem.extra_discount);
            setSubtotal(parseFloat(saleprice*saleqty).toFixed(2));
 }else{
   Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Store Does Not Have Enough Items',
                showConfirmButton: false,
                timer: 1500
              })
 }
                }
      return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header style={{backgroundColor:'blue',color:'white'}}>
            <Modal.Title id="contained-modal-title-vcenter" style={{Color:'darkblue'}}>
             Cart Items 
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <form onSubmit={handleSaveModel}>
          <div>
            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
<div>
<TextField
          label="Price"
        name='saleprice'
        value={saleprice}
        type='number'
       onChange={(e)=>handleChangeSalePrice(e)}
          size="small"
        />
</div>
<div>
<TextField
          label="Subtotal"
        name='subtotal'
        value={subtotal}
       disabled
          size="small"
        />

</div>
            </div>
            <div style={{marginTop:'4px',padding:'4px',display:'flex',justifyContent:'space-between',alignItems:'center'}}>

<div>
<FaPlusCircle style={{fontSize:'40px',color:'red'}} onClick={handleInc}/>
</div>
<div>
<TextField
          label="Qty"
        name='saleqty'
        value={saleqty}
        type='number'
      
       onChange={(e)=>handleChangeSaleQty(e)}
          size="small"
        />


</div>
<div>
<TextField
          label="totaldiscount"
        name='subtotaldiscount'
        value={subtotaldiscount}
        type='number'
       onChange={(e)=>handleSubTotalDiscount(e)}
          size="small"
        />
</div>
<div>
<FaMinusCircle  style={{fontSize:'40px',color:'orange'}} onClick={handleDec}/>
</div>
            </div>
            </div> 
          </form>
          </Modal.Body>
          <Modal.Footer>
          <Button style={{backgroundColor:'red'}}  onClick={props.onHide}>Close</Button>
            <Button onClick={handleSaveModel}>Save</Button>
          </Modal.Footer>
        </Modal>
      );
    }


    const handleSearchChange = (event) => {

      setSearchQuery(event.target.value);
      setCurrentPage(1);
    };

    
const handleSubmitCart=(id)=>{
    
        
        
          
     
        axios.get(`/api/fetchitem/${id}`).then(res=>{

          if(stringitems===null){




setCompleteItemProp(res.data.item);
    setModalShow(true);

          }
          else{

          
            if (stringitems.find((i) => i.id === res.data.item.id)) {
            
              Swal.fire({
                position: 'top-end',
                icon: 'info',
                title: 'Item already added',
                showConfirmButton: false,
                timer: 1500
              })
              
          }else{


         setCompleteItemProp(res.data.item);
//setSalePrice(res.data.item.unitsaleprice);
          
            setModalShow(true);
            
          }
        }
         
          });

       
        
      
      }


    useEffect(()=>{
        setIsClick(false);
axios.post(`api/productdetail/${id}?page=${currentPage}&search=${searchQuery}`).then(res=>{

setProducts(res.data.data);
setTotalPages(res.data.last_page);

if((res.data.data).length===0){
    setNoRecord(true);

}
else{
    setNoRecord(false);

}

setIsClick(true);
})


    },[id,currentPage,searchQuery])
  return (

   
   
     
   
    
    <div className='products row' >
      
      <input className='form-control' placeholder='Search Item' value={searchQuery} onChange={handleSearchChange}></input>

      

{norecord ? 'No Item Found':''}



    {
      
    isClick?

   
    products && products.map((item,index)=>(
    <button disabled={item.qty>0?false:true}  key={item.id} className='productboxes col-md-4 animated fadeInDown' onClick={()=>handleSubmitCart(item.id)}>
    
    <p style={{color:'blue',fontSize:'14px'}}>{item.name}</p>
    <span>Price:{item.unitsaleprice}</span>
    <p style={{color:'red'}}>Stock:{item.qty}</p>
    
     <FaCartArrowDown style={{fontSize:'30px',color:'green'}}/>
    
    </button>
    ))
    :<center>Please Wait...</center>
    
    }
  <div style={{textAlign:'center',marginTop:'10px'}}>
  <button
            disabled={currentPage === 1}
            onClick={() => setCurrentPage((page) => page - 1)}
    
            className='btn btn-warning btn-sm mr-3'
          >
            Previous
          </button>
          <span>{currentPage} of {totalPages}</span>
          <button
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage((page) => page + 1)}
            className='btn btn-danger btn-sm'
          >
            Next 
          </button>
  </div>
  <MyVerticallyCenteredModal
    show={modalShow}
    onHide={() => setModalShow(false)}
completeitem={completeitemprop}
 


  />
    </div>
  )
}

export default ProductsDetail
